import React, { useState } from "react"
import styled from "styled-components"
import Img from "gatsby-image"
import { withPreview } from "gatsby-source-prismic"

import Layout from "../components/layout/layout"
import SEO from "../components/seo"
import LocationNavigation from "../components/location/navigation"
import PageHeader from "../components/pageHeader"
import { MapImageContainer, NavigationContainer } from "./rooms-layout"
import Filter from "../components/atoms/filter"

import { useLocationPageData } from "../hooks/useLocationPageData"

const LocationPage = ({ data: previewData }) => {
  const { uid, bookData, data } = useLocationPageData(previewData)
  const [current, setCurrent] = useState<number>(0)
  const navItems = data.navigation_images.map(nav => nav.navigation_text)

  return (
    <Layout bookBannerData={bookData}>
      <SEO title={uid} />
      <Container>
        <PageHeader
          title={data.title}
          tagline={data.tagline}
          text={data.text}
        />
        <NavigationContainer>
          <Filter options={navItems} onChange={setCurrent} breakpoint="750px" />
          <LocationNavigation
            navItems={navItems}
            setImage={setCurrent}
            current={current}
          />
        </NavigationContainer>

        <MapImageContainer>
          <Img
            style={{ height: "100%" }}
            fluid={data.navigation_images[current].navigation_image.fluid}
            alt={
              data.navigation_images[current].navigation_image.alt ||
              `resort map ${current + 1}`
            }
          />
        </MapImageContainer>
      </Container>
    </Layout>
  )
}

export default withPreview(LocationPage)

const Container = styled.div``

const Map = styled.iframe`
  width: 80%;
  height: 400px;
  margin: 0 auto;
  padding-bottom: 80px;
  display: block;
`
