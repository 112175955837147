import { graphql, useStaticQuery } from "gatsby"
import { mergePrismicPreviewData } from "gatsby-source-prismic"

export const useLocationPageData = previewData => {
  const staticData = useStaticQuery(graphql`
    query Query {
      prismicLocationPage {
        _previewable
        uid
        data {
          title {
            html
          }
          text {
            text
          }
          tagline {
            text
          }
          navigation_images {
            navigation_text
            navigation_image {
              fluid(maxHeight: 400) {
                aspectRatio
                sizes
                src
                srcSet
              }
              alt
            }
          }
          body {
            ... on PrismicLocationPageBodyBook {
              id
              slice_type
              primary {
                button_text
                link {
                  url
                  link_type
                }
                text {
                  text
                }
              }
            }
          }
        }
      }
    }
  `)
  const mergedData: any = mergePrismicPreviewData({ staticData, previewData })
  const { uid, data } = mergedData.prismicLocationPage
  return {
    uid,
    bookData: data.body[0] ? data.body[0].primary : undefined,
    data,
  }
}
